<template>
  <div class="container" ref="container">
    <div ref="buttonOut">
      <el-form
        :model="queryParams"
        @keyup.enter.native="handlePage()"
        inline
        label-suffix="："
      >
        <el-form-item label="所属客户">
          <el-input
            v-model="queryParams.customer"
            class="fixedWidth200"
            size="small"
            placeholder="所属客户"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="网站名称">
          <el-input
            v-model="queryParams.siteName"
            class="fixedWidth200"
            size="small"
            placeholder="网站名称"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="网站域名">
          <el-input
            v-model="queryParams.domainName"
            class="fixedWidth200"
            size="small"
            placeholder="网站域名"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="handlePage()" type="primary">
            查询
          </el-button>
        </el-form-item>
        <el-form-item>
          <el-button size="small" @click="addDomainName()" type="primary">
            新增域名
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table :data="tableData" :height="tableHeight + 'px'">
      <el-table-column
        type="index"
        label="序号"
        width="50"
        align="center"
      ></el-table-column>
      <el-table-column label="网站logo" align="center">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.logo"
            :src="scope.row.logo"
            style="height: 40px;"
            fit="contain"
            :preview-src-list="[scope.row.logo]"
          ></el-image>
          <span v-else>未上传</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="siteName"
        label="网站名称"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="domainName"
        label="网站域名"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="customer"
        label="所属客户"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        prop="siteNameImage"
        label="网站名称图片"
        align="center"
        show-overflow-tooltip
      >
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.siteNameImage"
            :src="scope.row.siteNameImage"
            style="height: 40px;"
            fit="contain"
            :preview-src-list="[scope.row.siteNameImage]"
          >
          </el-image>
          <span v-else>未上传</span>
        </template>
      </el-table-column>
      <el-table-column label="背景图" align="center">
        <template slot-scope="scope">
          <el-image
            v-if="scope.row.backImage"
            :src="scope.row.backImage"
            style="height: 40px;"
            fit="contain"
            :preview-src-list="[scope.row.backImage]"
          ></el-image>
          <span v-else>未上传</span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" size="mini" @click="editDomainName(scope.row)">
            修改
          </el-button>
          <el-button
            type="text"
            style="color: #F56C6C"
            size="mini"
            @click="delDomainName(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="totalCount"
      @size-change="handlePageSize"
      @current-change="handlePage"
      :current-page="queryParams.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="queryParams.pageSize"
      :total="totalCount"
      layout="total, sizes, prev, pager, next, jumper"
    >
    </el-pagination>
    <addEdit ref="addDomainNameRef" @success="getList" />
  </div>
</template>

<script>
import addEdit from "./components/addEdit.vue";
export default {
  components: { addEdit },
  data() {
    return {
      queryParams: {
        domainName: "",
        siteName: "",
        customer: "",
        pageNo: 1,
        pageSize: 20,
      },
      tableData: [],
      totalCount: 0,
      tableHeight: null,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.tableHeight =
        this.$refs.container.clientHeight -
        this.$refs.buttonOut.clientHeight -
        // 分页高度32px + margin-top 15px
        (32 + 15);
    });
    this.handlePage();
  },
  methods: {
    handlePage(page = 1) {
      this.queryParams.pageNo = page;
      this.getList();
    },
    handlePageSize(size) {
      this.queryParams.pageSize = size;
      this.queryParams.pageNo = 1;
      this.getList();
    },
    getList() {
      this.$http({
        url: this.$http.adornUrl("/domain/name/manage/list"),
        method: "get",
        params: this.$http.adornParams(this.queryParams),
      })
        .then((res) => {
          this.tableData = res.data.data || [];
          this.totalCount = Number(res.data.count || "0");
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    // 新增域名
    addDomainName() {
      this.$refs.addDomainNameRef.init();
    },
    // 修改域名
    editDomainName(data) {
      this.$refs.addDomainNameRef.init(data);
    },
    // 删除域名
    delDomainName(data) {
      this.$confirm("是否确认删除", "请确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        const Loading = this.$mask();
        this.$http({
          url: this.$http.adornUrl("/domain/name/manage/del"),
          method: "delete",
          params: {
            id: data.id,
          },
        })
          .then((res) => {
            Loading.close();
            this.$message.success(res.data.msg);
            this.getList();
          })
          .catch((err) => {
            Loading.close();
            this.$message.error(err.msg);
          });
      });
    },
  },
};
</script>

<style></style>
