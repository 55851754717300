<template>
  <el-dialog
    :title="isEdit ? '修改设备' : '新增设备'"
    :close-on-click-modal="false"
    :visible.sync="visible"
    width="580px"
  >
    <el-form
      :model="dataForm"
      :rules="dataRule"
      ref="dataForm"
      @keyup.enter.native="dataFormSubmit()"
      label-width="80px"
    >
      <el-form-item label="设备类型" prop="type" v-if="!isHideAcc">
        <el-select
          class="fixedWidth400"
          v-model="dataForm.type"
          placeholder="设备类型"
          @change="handleType"
        >
          <el-option label="麦卡途" :value="0"></el-option>
          <el-option label="亚美" :value="1"></el-option>
          <el-option label="有线设备" :value="2"></el-option>
          <el-option label="BD8826LHA700" :value="3"></el-option>
          <el-option label="交投设备" :value="4"></el-option>
          <el-option label="54所设备" :value="5"></el-option>
        </el-select>
      </el-form-item>
      <template v-if="dataForm.type === 3">
        <div style="padding-bottom: 10px;">设备归属地</div>
        <div style="height: 50px;">
          <el-radio v-model="dataForm.belong" :label="1"
            >地市车辆 - 自动连接市级平台和省级平台</el-radio
          >
          <el-select
            v-model="dataForm.city"
            v-if="dataForm.belong === 1"
            size="small"
            placeholder="选择归属地"
          >
            <el-option
              v-for="[value, label] in cityDictArr"
              :label="label"
              :value="value"
              :key="value"
            ></el-option>
          </el-select>
        </div>
        <div style="height: 50px;">
          <el-radio v-model="dataForm.belong" :label="2"
            >中直车辆 - 自动连接市级平台</el-radio
          >
          <el-select
            v-model="dataForm.city"
            v-if="dataForm.belong === 2"
            size="small"
            placeholder="选择归属地"
          >
            <el-option
              v-for="[value, label] in cityDictArr"
              :label="label"
              :value="value"
              :key="value"
            ></el-option>
          </el-select>
        </div>
        <div style="height: 50px;">
          <el-radio v-model="dataForm.belong" :label="3"
            >省直车辆 - 自动连接省级平台</el-radio
          >
        </div>
        <div style="height: 50px;">
          <el-radio v-model="dataForm.belong" :label="4"
            >租赁车辆 - 自动连接市级平台</el-radio
          >
          <el-select
            v-model="dataForm.city"
            v-if="dataForm.belong === 4"
            size="small"
            placeholder="选择归属地"
          >
            <el-option
              v-for="[value, label] in cityDictArr"
              :label="label"
              :value="value"
              :key="value"
            ></el-option>
          </el-select>
        </div>
        <div style="height: 50px;">
          <el-radio v-model="dataForm.belong" :label="5"
            >内部车辆 - 自动连接市级平台</el-radio
          >
          <el-select
            v-model="dataForm.city"
            v-if="dataForm.belong === 5"
            size="small"
            placeholder="选择归属地"
          >
            <el-option
              v-for="[value, label] in cityDictArr"
              :label="label"
              :value="value"
              :key="value"
            ></el-option>
          </el-select>
        </div>
        <div style="height: 50px;">
          <el-radio v-model="dataForm.belong" :label="6">公务快车</el-radio>
        </div>
        <div style="height: 50px;">
          <el-radio v-model="dataForm.belong" :label="7">自动连接一网通和省级平台</el-radio>
        </div>
      </template>
      <el-form-item label="设备编号" prop="id">
        <el-input
          class="fixedWidth400"
          v-model="dataForm.id"
          placeholder="设备编号"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="车牌号"
        prop="carPlateNum"
        :rules="carPlateNumRules"
        ref="carPlateNumRule"
      >
        <el-input
          style="width: 300px"
          v-model="dataForm.carPlateNum"
          placeholder="车牌号"
        ></el-input>
        <span style="display: inline-block; width: 20px"></span>
        <el-checkbox v-model="isRules" v-if="!isEdit">验证格式</el-checkbox>
      </el-form-item>
      <!-- <el-form-item label="车辆品牌" prop="carBrand">
        <el-select
          class="fixedWidth400"
          v-model="dataForm.carBrand"
          placeholder="车辆品牌"
        >
          <el-option label="蓝" value="蓝"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="车辆车系" prop="carSeries">
        <el-select
          class="fixedWidth400"
          v-model="dataForm.carSeries"
          placeholder="车辆车系"
        >
          <el-option label="蓝" value="蓝"></el-option>
        </el-select>
      </el-form-item> -->

      <el-form-item label="车牌颜色" prop="carPlateColor">
        <el-select
          class="fixedWidth400"
          v-model="dataForm.carPlateColor"
          placeholder="车牌颜色"
        >
          <el-option label="蓝" value="蓝"></el-option>
          <el-option label="黄" value="黄"></el-option>
          <el-option label="黑" value="黑"></el-option>
          <el-option label="白" value="白"></el-option>
          <el-option label="绿" value="绿"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="品牌车系" prop="cascaderValue">
        <carBSCascader
          style="width: 200px"
          :hasAll="false"
          v-model="dataForm.cascaderValue"
        />
      </el-form-item>
      <el-form-item label="负责人" prop="contacts">
        <el-input
          class="fixedWidth400"
          v-model="dataForm.contacts"
          placeholder="负责人"
        ></el-input>
      </el-form-item>
      <el-form-item label="联系方式" prop="contactsPhone">
        <el-input
          class="fixedWidth400"
          v-model="dataForm.contactsPhone"
          placeholder="联系方式"
        ></el-input>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button type="primary" @click="dataFormSubmit()">确定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { formRulesRegExp } from "@/utils/validate";
import carBSCascader from "../../components/carBSCascader.vue";
export default {
  components: { carBSCascader },
  props: {
    orgData: {
      type: Object,
      default: () => {
        return {
          orgId: "",
        };
      },
    },
    isHideAcc: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    carPlateNumRules() {
      let carPlateNum = [];
      if (this.isRules) {
        return [
          { required: true, trigger: "blur", message: "请输入车牌号" },
          {
            type: "string",
            pattern: formRulesRegExp("cph"),
            message: "车牌号格式错误",
            trigger: "blur",
          },
        ];
      } else {
        return [{ required: true, trigger: "blur", message: "请输入车牌号" }];
      }
    },
  },
  data() {
    const cityDict = new Map([
      ["shijiazhuang", "石家庄"],
      ["tangshan", "唐山"],
      ["qinhuangdao", "秦皇岛"],
      ["handan", "邯郸"],
      ["xingtai", "邢台"],
      ["baoding", "保定"],
      ["zhangjiakou", "张家口"],
      ["chengde", "承德"],
      ["cangzhou", "沧州"],
      ["langfang", "廊坊"],
      ["hengshui", "衡水"],
      ["xiongan", "雄安"],
      ["xinji", "辛集"],
      ["dingzhou", "定州"],
    ]);
    return {
      cityDict,
      cityDictArr: [...cityDict],
      visible: false,
      roleList: [],
      dataForm: {
        id: "",
        carPlateNum: "",
        carPlateColor: "",
        orgId: "",
        type: "",
        contacts: "",
        contactsPhone: "",
        cascaderValue: [],
        belong: "",
        city: "",
      },
      isRules: true,
      dataRule: {
        id: [
          { required: true, trigger: "blur", message: "请输入设备编号" },
          {
            type: "string",
            pattern: formRulesRegExp("shuzi"),
            message: "请输入数字",
            trigger: "blur",
          },
          {
            trigger: "blur",
            validator: (rule, value, callback) => {
              let startNum = value.toString().substring(0, 4);
              let isValidStartNum = ["3755", "3754", "1487", "1001"].includes(startNum);
              let isA700 = this.dataForm.type === 3;
              // 如果设备类型不是A700,但设备号匹配了A700
              if (!isA700 && isValidStartNum) {
                callback(new Error("设备号与设备类型不匹配"));
              } else if (isA700 && !isValidStartNum) {
                // 设备类型是A700,但设备号不匹配A700
                callback(new Error("设备号与设备类型不匹配"));
              } else {
                // 其他情况
                callback();
              }
            },
          },
        ],
        carPlateColor: [
          { required: true, trigger: "change", message: "请选择车牌颜色" },
        ],
        type: { required: true, message: "请选择设备类型", trigger: "change" },
        // cascaderValue: {
        //   required: true,
        //   trigger: "change",
        //   message: "请选择品牌车系",
        // },
        // contacts: {
        //   required: true,
        //   trigger: "blur",
        //   message: "请选择负责人",
        // },
        // contactsPhone: {
        //   required: true,
        //   trigger: "blur",
        //   message: "请选择联系人",
        // },
      },
      isEdit: false,
      oldId: "",
    };
  },
  methods: {
    init(row) {
      this.visible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].resetFields();
        if (row) {
          this.oldId = JSON.parse(JSON.stringify(row.id));
          this.isRules = false;
          this.dataForm = { ...row };
          this.dataForm.cascaderValue = [row.carBrand, row.carSeries];
          this.isEdit = true;
        } else {
          this.isEdit = false;
          this.dataForm.id = "";
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          if (this.dataForm.type === 3) {
            if (!this.dataForm.belong) {
              return this.$message.warning("请选择设备归属地");
            }
            if (![3, 6, 7].includes(this.dataForm.belong) && !this.dataForm.city) {
              return this.$message.warning("请选择设备归属地");
            }
          }
          const Loading = this.$mask();
          if (this.isEdit) {
            this.$http({
              url: this.$http.adornUrl("obd/update"),
              method: "put",
              data: {
                id: this.dataForm.id.trim(),
                carPlateNum: this.dataForm.carPlateNum.trim(),
                carPlateColor: this.dataForm.carPlateColor,
                orgId: this.dataForm.orgId,
                carBrand: this.dataForm.cascaderValue[0] || "",
                carSeries: this.dataForm.cascaderValue[1] || "",
                contacts: this.dataForm.contacts
                  ? this.dataForm.contacts.trim()
                  : "",
                contactsPhone: this.dataForm.contactsPhone
                  ? this.dataForm.contactsPhone.trim()
                  : "",
                type: this.isHideAcc ? 4 : this.dataForm.type,
                oldId: this.oldId,
                belong: this.dataForm.belong,
                city: [3, 6, 7].includes(this.dataForm.belong) ? "" : this.dataForm.city,
              },
            })
              .then((res) => {
                Loading.close();
                this.$message.success(res.data.msg);
                this.$refs["dataForm"].resetFields();
                this.visible = false;
                this.$emit("refreshDataList");
              })
              .catch((err) => {
                Loading.close();
                this.$message.error(err.msg);
              });
          } else {
            this.$http({
              url: this.$http.adornUrl("/obd/add"),
              method: "post",
              data: {
                id: this.dataForm.id,
                carPlateNum: this.dataForm.carPlateNum.trim(),
                carPlateColor: this.dataForm.carPlateColor,
                orgId: this.orgData.id,
                type: this.isHideAcc ? 4 : this.dataForm.type,
                carBrand: this.dataForm.cascaderValue[0] || "",
                carSeries: this.dataForm.cascaderValue[1] || "",
                contacts: this.dataForm.contacts,
                contactsPhone: this.dataForm.contactsPhone,
                belong: this.dataForm.belong,
                city: [3, 6, 7].includes(this.dataForm.belong) ? "" : this.dataForm.city,
              },
            })
              .then((res) => {
                Loading.close();
                this.$message.success(res.data.msg);
                this.$refs["dataForm"].resetFields();
                this.visible = false;
                this.$emit("refreshDataList");
              })
              .catch((err) => {
                Loading.close();
                this.$message.error(err.msg);
              });
          }
        }
      });
    },
    // 选择设备类型触发
    handleType(val) {
      this.dataForm.belong = "";
      this.dataForm.city = "";
    },
  },
};
</script>
