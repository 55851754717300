import Vue from "vue";
import axios from "axios";
import router from "@/router";
import qs from "qs";
import merge from "lodash/merge";
import { clearLoginInfo, getToken, type } from "@/utils";

const http = axios.create({
  timeout: 1000 * 60 * 2,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

/**
 * 请求拦截
 */
http.interceptors.request.use(
  (config) => {
    config.headers["token"] = getToken(); // 请求头带上token
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * 响应拦截
 */
http.interceptors.response.use(
  (response) => {
    if (
      (response.config.responseType == "arraybuffer" ||
        response.config.responseType == "blob") &&
      response.data.code == undefined
    ) {
      return response.data;
    } else if (response.data && response.data.code === 200) {
      return response;
    } else if (response.data && response.data.code === 401) {
      // 401, token失效
      clearLoginInfo();
      return;
    } else {
      return Promise.reject({
        code: response.data.code || 500,
        msg: response.data.msg || "服务器繁忙",
      });
    }
  },
  (error) => {
    return Promise.reject({
      code: 300,
      msg: "网络错误或服务器繁忙",
    });
  }
);

/**
 * 请求地址处理
 * @param {*} actionName action方法名称
 */
http.adornUrl = (actionName) => {
  // http://192.168.124.66:7002
  // http://111.62.218.236:7002/
  //https://admin.obd.hebywt.com:7008/
  // http://192.168.124.66:8008/
  // http://192.168.124.169:8008/ 志强
  let _actionName = actionName.startsWith("/") ? actionName.slice(1) : actionName;
  return (
    (process.env.NODE_ENV === "development"
      ? "http://192.168.124.66:8008/"
      : "https://admin.obd.hebywt.com:7008/") + _actionName
  );
};

/**
 * get请求参数处理
 * @param {*} params 参数对象
 * @param {*} openDefultParams 是否开启默认参数?
 */
http.adornParams = (params = {}, openDefultParams = true) => {
  var defaults = {
    // 't': new Date().getTime()
  };
  return openDefultParams ? merge(defaults, params) : params;
};

/**
 * post请求数据处理
 * @param {*} data 数据对象
 * @param {*} openDefultdata 是否开启默认数据?
 * @param {*} contentType 数据格式
 *  json: 'application/json; charset=utf-8'
 *  form: 'application/x-www-form-urlencoded; charset=utf-8'
 */
http.adornData = (data = {}, openDefultdata = true, contentType = "json") => {
  var defaults = {
    // 't': new Date().getTime()
  };
  data = openDefultdata ? merge(defaults, data) : data;
  return contentType === "json" ? JSON.stringify(data) : qs.stringify(data);
};

http.FormData = (data) => {
  if (type(data) != "object") {
    return data;
  }
  const formData = new FormData();
  for (const key in data) {
    const item = data[key];
    if (Array.isArray(item)) {
      item.forEach((ii) => {
        formData.append(key, ii);
      });
    } else {
      formData.append(key, item);
    }
  }
  return formData;
};

export default http;
