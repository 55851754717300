<template>
  <div class="mod-org" ref="mod-org">
    <div class="container-left" :style="{ height: clientHeight + 'px' }">
      <el-input placeholder="输入关键字进行过滤" v-model="filterText" />
      <div class="container-left-tree">
        <el-tree
          ref="tree"
          :data="orgTree"
          :props="defaultProps"
          node-key="id"
          highlight-current
          @node-click="treeNodeClick"
          :expand-on-click-node="false"
          :default-expanded-keys="defaultExpanded"
          :filter-node-method="filterNode"
          :draggable="isDraggable"
          @node-drag-end="handleDragEnd"
          @node-drag-start="handleDragStart"
        ></el-tree>
      </div>
    </div>
    <div class="container-right">
      <div class="buttonOut">
        <el-button
          v-if="isAuth('sys:orgArch:addProvince')"
          type="primary"
          size="small"
          @click="addProvince()"
          >{{
            curTabName === "province" && !isReadonlyForm ? "保存" : "新增根节点"
          }}</el-button
        >
        <el-button type="primary" size="small" @click="addOrgNode">{{
          curTabName === "node" && !isReadonlyForm ? "保存" : "新增子节点"
        }}</el-button>
        <!-- 正在新增跟节点和子节点时置灰按钮 -->
        <el-button
          type="primary"
          size="small"
          @click="editTreeNodeName"
          :disabled="
            (curTabName === 'province' || curTabName === 'node') &&
              !isReadonlyForm
          "
        >
          {{
            curTabName === "updateProvince" && !isReadonlyForm
              ? "保存修改"
              : "修改节点"
          }}
        </el-button>
        <el-button
          type="danger"
          size="small"
          @click="delOrgNodeWarning"
          :disabled="!isReadonlyForm"
        >
          删除
        </el-button>
        <el-button
          v-if="false"
          @click="isDraggable = !isDraggable"
          type="primary"
          size="small"
        >
          {{
            isDraggable
              ? "已开启，点击关闭拖拽功能"
              : "已关闭，点击开启拖拽功能"
          }}
        </el-button>
      </div>
      <div class="content">
        <!-- 根节点表单 -->
        <el-form
          v-if="curTabName === 'province' || curTabName === 'updateProvince'"
          :model="provinceForm"
          label-width="100px"
          label-position="left"
          :disabled="isReadonlyForm"
        >
          <el-form-item label="根节点名称">
            <el-input
              v-model="provinceForm.name"
              class="fixedWidth400"
              size="small"
              placeholder="根节点名称"
            ></el-input>
          </el-form-item>
          <template v-if="false">
            <el-form-item label="网站域名">
              <el-input
                v-model="provinceForm.domainName"
                class="fixedWidth400"
                size="small"
                placeholder="网站域名"
              ></el-input>
            </el-form-item>
            <el-form-item label="网站名称">
              <el-input
                v-model="provinceForm.siteName"
                class="fixedWidth400"
                size="small"
                placeholder="网站名称"
              ></el-input>
            </el-form-item>
            <el-form-item label="上传Logo">
              <PhotoSingle
                ref="logoRef"
                :value="imageValue.logo"
                @on-change="(file) => handleChange('logo', file)"
              />
            </el-form-item>
            <el-form-item label="背景图">
              <PhotoSingle
                ref="backImageRef"
                :value="imageValue.backImage"
                @on-change="(file) => handleChange('backImage', file)"
              />
            </el-form-item>
          </template>
        </el-form>
        <!-- 子节点表单 -->
        <el-form
          v-if="curTabName === 'node'"
          :model="curNode"
          label-width="100px"
          label-position="left"
        >
          <el-form-item label="上级名称">
            <el-input
              v-model="curNode.parendName"
              class="fixedWidth400"
              size="small"
              placeholder="上级节点(不可修改)"
              disabled
            ></el-input>
          </el-form-item>
          <el-form-item label="名称">
            <el-input
              v-model="curNode.nodeName"
              class="fixedWidth400"
              size="small"
              placeholder="节点名称"
              :disabled="isReadonlyForm"
            ></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import { treeDataTranslate } from "@/utils";
import PhotoSingle from "@/components/photoSingle";
export default {
  name: "sys-org",
  components: { PhotoSingle },
  data() {
    return {
      orgTree: [],
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
      curNode: {},
      isShowAddProvince: false,
      filterText: "",
      beforeDragTreeData: [], //进行拖拽操作之前的数据
      isDraggable: false, //tree是否可拖拽
      curTabName: "", // 当前显示的标签页
      isReadonlyForm: true, // 表单是否只读
      // 根节点表单数据
      provinceForm: {
        name: "",
        id: null,
        backImage: null,
        domainName: null,
        logo: null,
        siteName: null,
      },
      // 图片回显
      imageValue: {
        backImage: "",
        logo: "",
      },
    };
  },
  computed: {
    clientHeight() {
      return document.documentElement["clientHeight"] - 122;
    },
    defaultExpanded() {
      let _B = Boolean(this.orgTree && this.orgTree.length);
      let arr = _B ? [this.orgTree[0].id] : [];
      return arr;
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  created() {},
  mounted() {
    this.getOrgList();
  },
  methods: {
    // 重置根节点表单
    resetProvinceForm(data = {}) {
      this.provinceForm = {
        name: data.name || data.nodeName || "",
        id: data.id ?? "",
        backImage: data.backImage ?? "",
        domainName: data.domainName ?? "",
        logo: data.logo ?? "",
        siteName: data.siteName ?? "",
      };
      this.imageValue.logo = data.logo ?? "";
      this.imageValue.backImage = data.backImage ?? "";
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.nodeName.indexOf(value) !== -1;
    },
    getOrgList() {
      const Loading = this.$mask();
      this.$http({
        url: this.$http.adornUrl("/sys/orgArch/list"),
        methods: "get",
      })
        .then((res) => {
          Loading.close();
          this.orgTree = treeDataTranslate(res.data.data, "id", "parendId");
        })
        .catch((err) => {
          Loading.close();
          this.$message.error(err.msg);
        });
    },
    treeNodeClick(node) {
      this.isReadonlyForm = true;
      this.curNode = JSON.parse(JSON.stringify(node));
      if (!node.parendId || node.parendId === "0") {
        this.curTabName = "province";
        this.resetProvinceForm(node);
      } else {
        this.curTabName = "node";
      }
    },
    addOrgNode() {
      if (this.curTabName != "node" || this.isReadonlyForm) {
        //点击插入,重置表单
        if (!this.curNode.id) return this.$message.warning("请选择节点!");
        this.curTabName = "node";
        this.isReadonlyForm = false;
        let _parendName = JSON.parse(JSON.stringify(this.curNode.nodeName));
        this.curNode.parendName = _parendName;
        this.curNode.nodeName = "";
        this.curNode.nodeType += 1;
      } else {
        //点击保存
        let _ids = this.curNode.parendIds
          ? this.curNode.parendIds.split(",")
          : [];
        _ids.push(this.curNode.id);
        let parendIds = _ids.filter((item) => !!item);
        const Loading = this.$mask();
        this.$http({
          url: this.$http.adornUrl("/sys/orgArch/add"),
          method: "post",
          data: {
            nodeName: this.curNode.nodeName,
            parendId: this.curNode.id,
            parendIds: parendIds.join(),
            nodeType: this.curNode.nodeType,
          },
        })
          .then((res) => {
            Loading.close();
            this.$message.success(res.data.msg);
            this.isReadonlyForm = true;
            this.getOrgList();
          })
          .catch((err) => {
            Loading.close();
            this.$message.error(err.msg);
          });
      }
    },
    // 新增根节点
    addProvince(isUpdate) {
      if ((this.curTabName != "province" || this.isReadonlyForm) && !isUpdate) {
        this.resetProvinceForm(); // 重置表单数据
        this.curTabName = "province"; // 显示表单
        this.isReadonlyForm = false; // 解除表单只读
      } else {
        const Loading = this.$mask();
        this.$http({
          url: this.$http.adornUrl(`sys/orgArch/addProvince`),
          method: "post",
          data: this.$http.FormData(this.provinceForm),
        })
          .then((res) => {
            Loading.close();
            this.$message.success(res.data.msg);
            this.isReadonlyForm = true;
            this.getOrgList();
            this.resetProvinceForm();
            this.$refs.logoRef.clear();
            this.$refs.backImageRef.clear();
          })
          .catch((err) => {
            Loading.close();
            this.$message({
              type: "error",
              message: err,
            });
          });
      }
    },
    //修改节点名称
    editTreeNodeName() {
      let _curNode = this.curNode;
      if (
        _curNode.id == "1" ||
        _curNode.id == "1341622163294666753" ||
        _curNode.id == "1341626966192177153" ||
        _curNode.id == "1342371808009728002" ||
        _curNode.id == "1343761846358503425" ||
        _curNode.id == "1346640692552392706" ||
        _curNode.id == "1363388273328275458" ||
        _curNode.id == "1364398011004370946" ||
        _curNode.id == "1367358083313582082" ||
        _curNode.id == "1376354833005252609" ||
        _curNode.id == "1379637575553880065" ||
        _curNode.id == "1391681987200933890" ||
        _curNode.id == "1394120285190074370" ||
        _curNode.id == "1394834626495361026" ||
        _curNode.id == "1402100795329609729"
      ) {
        return this.$message.warning("此节点不能修改!");
      }
      if (!this.curNode.id) return this.$message.error("请选择节点!");
      // 修改根节点执行此段代码
      if (
        (this.curNode.parendId === "0" || !this.curNode.parendId) &&
        this.isReadonlyForm
      ) {
        this.curTabName = "updateProvince";
        this.isReadonlyForm = false;
        return;
      }
      // 保存修改根节点操作
      if (this.curTabName === "updateProvince" && !this.isReadonlyForm) {
        this.addProvince(true);
        return;
      }
      // 修改子节点
      this.$prompt(`当前节点名称：${this.curNode.nodeName}`, "修改节点名称", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        inputPattern: /\S/,
        inputErrorMessage: "请输入名称",
        inputPlaceholder: "请输入节点名称",
      })
        .then(({ value }) => {
          if (!this.isAuth("sys:orgArch:updateNodeName"))
            return this.$message.warning("没有权限!sys:orgArch:updateNodeName");
          return this.$http({
            url: this.$http.adornUrl(`sys/orgArch/updateNodeName`),
            method: "put",
            params: this.$http.adornParams({
              orgId: this.curNode.id,
              nodeName: value,
            }),
          });
        })
        .then((res) => {
          this.getOrgList();
          this.$message.success(res.data.msg);
        })
        .catch((err) => {
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
    delOrgNodeWarning() {
      this.$confirm("是否删除节点", "请确认", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.delOrgNode();
      });
    },
    delOrgNode() {
      let _curNode = this.curNode;
      if (
        _curNode.id == "1" ||
        _curNode.id == "1341622163294666753" ||
        _curNode.id == "1341626966192177153" ||
        _curNode.id == "1342371808009728002" ||
        _curNode.id == "1343761846358503425" ||
        _curNode.id == "1346640692552392706" ||
        _curNode.id == "1363388273328275458" ||
        _curNode.id == "1364398011004370946" ||
        _curNode.id == "1367358083313582082" ||
        _curNode.id == "1376354833005252609" ||
        _curNode.id == "1379637575553880065" ||
        _curNode.id == "1391681987200933890" ||
        _curNode.id == "1394120285190074370" ||
        _curNode.id == "1394834626495361026" ||
        _curNode.id == "1402100795329609729"
      ) {
        return this.$message.warning("此节点不能删除!");
      }
      const Loading = this.$mask();
      let id = this.curNode.id;
      this.$http({
        url: this.$http.adornUrl("/sys/orgArch/del"),
        method: "delete",
        params: {
          id,
        },
      })
        .then((res) => {
          Loading.close();
          this.$message.success(res.data.msg);
          this.getOrgList();
          this.resetProvinceForm();
        })
        .catch((err) => {
          Loading.close();
          this.$message.error(err.msg);
        });
    },
    //开始拖拽， 保存未拖拽之前的数据
    handleDragStart() {
      this.beforeDragTreeData = JSON.parse(JSON.stringify(this.orgTree));
    },
    handleDragEnd(draggingNode, dropNode, dropType, ev) {
      let data = null;
      if (dropType == "inner") {
        let _ids = dropNode.data.parendIds.split(",");
        _ids.push(dropNode.data.id);
        let parendIds = _ids.filter((item) => !!item);
        data = {
          id: draggingNode.data.id,
          nodeName: draggingNode.data.nodeName,
          nodeType: dropNode.data.nodeType + 1,
          parendId: dropNode.data.id,
          parendIds: parendIds.join(),
          parendName: dropNode.data.nodeName,
        };
      } else if (dropType == "after" || dropType == "before") {
        data = {
          id: draggingNode.data.id,
          nodeName: draggingNode.data.nodeName,
          nodeType: dropNode.data.nodeType,
          parendId: dropNode.data.parendId,
          parendIds: dropNode.data.parendIds,
          parendName: dropNode.data.parendName,
        };
      } else {
        this.orgTree = JSON.parse(JSON.stringify(this.beforeDragTreeData));
        return;
      }
      // console.log("当前节点", draggingNode.data);
      console.log("拖入节点", dropNode.data);
      console.log("新节点", JSON.parse(JSON.stringify(data)));
      this.$confirm(`确定对单位节点进行拖拽操作吗?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return this.$http({
            url: this.$http.adornUrl("sys/orgArch/update"),
            method: "put",
            data,
          });
        })
        .then((res) => {
          this.$message.success(res.data.msg);
          this.getOrgList();
        })
        .catch((err) => {
          // this.orgTree = JSON.parse(JSON.stringify(this.beforeDragTreeData));
          this.getOrgList();
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
    // 上传文件
    handleChange(key, file) {
      this.provinceForm[key] = file;
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 50%;
  padding: 8px;
}
</style>
