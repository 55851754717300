<template>
  <div class="device-manage" ref="container">
    <div class="container-left" :style="{ height: '100%' }">
      <el-input
        placeholder="输入关键字进行过滤"
        style="margin-bottom: 5px;"
        size="small"
        v-model="filterText"
      />
      <div class="container-left-tree">
        <el-tree
          ref="tree"
          :data="orgTree"
          :props="defaultProps"
          node-key="id"
          highlight-current
          @node-click="treeNodeClick"
          :expand-on-click-node="false"
          :default-expanded-keys="defaultExpanded"
          :filter-node-method="filterNode"
        ></el-tree>
      </div>
    </div>
    <div class="container-right" ref="right">
      <div
        class="buttonOut"
        ref="buttonOut"
        style="flex-wrap: wrap; height: auto;"
      >
        <div style="margin-right: 18px">
          <span class="mr1">设备号</span>
          <el-input
            v-model="id"
            clearable
            size="small"
            style="width: 150px"
            placeholder="请输入设备号"
          />
        </div>
        <div style="margin-right: 18px">
          <span class="mr1">车牌号</span>
          <el-input
            v-model="carPlateNum"
            clearable
            size="small"
            style="width: 150px"
            placeholder="请输入车牌号"
          />
        </div>
        <div style="margin-right: 18px">
          <span class="mr1">车牌颜色</span>
          <el-select
            v-model="carPlateColor"
            clearable
            size="small"
            style="width: 150px"
            placeholder="请选择车牌颜色"
          >
            <el-option label="蓝" value="蓝"></el-option>
            <el-option label="黄" value="黄"></el-option>
            <el-option label="黑" value="黑"></el-option>
            <el-option label="白" value="白"></el-option>
            <el-option label="绿" value="绿"></el-option>
          </el-select>
        </div>
        <div style="margin-right: 18px" v-if="!isHideAcc">
          <span class="mr1">设备类型</span>
          <el-select
            v-model="type"
            clearable
            size="small"
            style="width: 150px"
            placeholder="请选择设备类型"
          >
            <el-option label="全部" value="-1"></el-option>
            <el-option
              v-for="[value, label] in typeList"
              :key="value"
              :label="label"
              :value="value"
            ></el-option>
          </el-select>
        </div>
        <div style="margin-right: 18px">
          <span class="mr1">创建日期</span>
          <el-date-picker
            v-model="time"
            type="datetimerange"
            size="small"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </div>
        <div>
          <el-button
            type="primary"
            style="margin: 5px 0;"
            size="small"
            @click="currentChangeHandle(1)"
          >
            查询
          </el-button>
          <el-button size="small" @click="reset">
            重置
          </el-button>
          <template v-if="!isGcAdmin">
            <el-button
              v-if="isAuth('obd:add')"
              size="small"
              type="primary"
              @click="addHandle"
            >
              新增
            </el-button>
            <el-button
              v-if="isAuth('obd:notice')"
              size="small"
              type="primary"
              @click="noticeHandle"
            >
              通知
            </el-button>
            <el-button
              v-if="isAuth('obd:cancelnotice')"
              size="small"
              @click="cancelNoticeHandle"
            >
              取消通知
            </el-button>
            <el-button
              v-if="!isHideAcc"
              size="small"
              type="primary"
              @click="batchUpdate"
            >
              批量同步
            </el-button>
            <el-button
              v-if="!isHideAcc"
              type="primary"
              size="small"
              @click="resetTime()"
            >
              刷新上报时间
            </el-button>
            <!-- 隐藏 gps 相关 2023.10.18 产品：赵嘉宾 -->
            <el-button
              size="small"
              type="primary"
              v-if="isAuth('sys:close:gps') && false"
              @click="handleCloseGPS"
            >
              批量关闭GPS
            </el-button>
            <el-button v-if="!isHideAcc" size="small" @click="handleExport"
              >导出</el-button
            >
          </template>
        </div>
      </div>
      <div style="width: 100%; padding: 8px">
        <el-table
          :data="tableData"
          @selection-change="handleSelectionChange"
          :height="clientHeight + 'px'"
          border
        >
          <el-table-column
            class="selection"
            v-if="isAuth('multiple:choice')"
            type="selection"
            width="55"
            align="center"
          >
          </el-table-column>
          <el-table-column
            type="index"
            width="50"
            label="序号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="id"
            label="设备编号"
            width="120"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            v-if="!isHideAcc"
            prop="bdNum"
            label="单北斗设备号"
            width="130"
            align="center"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.bdNum }}</span>
              <el-tooltip
                content="清除单北斗设备号"
                placement="top"
                v-if="scope.row.bdNum"
              >
                <el-button
                  type="text"
                  size="mini"
                  @click="handleRemoveOnly(scope.row)"
                  icon="el-icon-circle-close"
                >
                </el-button>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            prop="carPlateNum"
            label="车牌号"
            width="100"
            align="center"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            prop="carPlateColor"
            label="车牌颜色"
            width="80"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="nodeName"
            label="单位名称"
            width="120"
            align="center"
            show-overflow-tooltip
          />
          <el-table-column label="设备类型" width="130" align="center">
            <div slot-scope="scope">
              <!-- {{
                scope.row.type == 0
                  ? "麦卡途"
                  : scope.row.type == 1
                  ? "亚美"
                  : scope.row.type == 2
                  ? "有线设备"
                  : scope.row.type == 3
                  ? "BD8826LHA700"
                  : "-"
              }} -->
              {{ typeMap.get(scope.row.type) }}
            </div>
          </el-table-column>
          <!-- 隐藏 gps 相关 2023.10.18 产品：赵嘉宾 -->
          <el-table-column
            label="GPS状态"
            width="100"
            align="center"
            v-if="false"
          >
            <div slot-scope="scope">
              {{
                scope.row.gpsState === 1
                  ? "使用"
                  : scope.row.gpsState === 0
                  ? "未使用"
                  : ""
              }}
            </div>
          </el-table-column>

          <el-table-column
            prop="createTime"
            label="创建时间"
            align="center"
            width="160"
            show-overflow-tooltip
          ></el-table-column>
          <el-table-column
            label="是否关闭单北斗信号"
            align="center"
            width="150"
            show-overflow-tooltip
          >
            <template slot-scope="scope">
              <span>{{ scope.row.strictStatus === 1 ? "是" : "否" }}</span>
            </template>
          </el-table-column>

          <template v-if="!isGcAdmin && !isHideAcc">
            <el-table-column align="center" width="120">
              <template slot="header">
                <el-dropdown
                  class="dropdown"
                  @command="($evens) => command('isStop', $evens)"
                >
                  <span class="el-dropdown-link">
                    是否停用<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="-1">全部</el-dropdown-item>
                    <el-dropdown-item command="1">是</el-dropdown-item>
                    <el-dropdown-item command="0">否</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <div slot-scope="scope">
                <!-- <el-switch
                v-loading="scope.row.isStopLoading"
                v-model="scope.row.isStop"
                :active-value="1"
                :inactive-value="0"
                active-text="是"
                inactive-text="否"
                @change="(val) => isStopChange(val, scope.row)"
              >
              </el-switch> -->
                <i-switch
                  v-model="scope.row.isStop"
                  :true-value="1"
                  :false-value="0"
                  true-color="#17B3A3"
                  @on-change="(val) => isStopChange(val, scope.row)"
                >
                  <span slot="open">是</span>
                  <span slot="close">否</span>
                </i-switch>
              </div>
            </el-table-column>
            <el-table-column prop="isSync" align="center" width="120">
              <template slot="header">
                <el-dropdown
                  class="dropdown"
                  @command="($evens) => command('isSync', $evens)"
                >
                  <span class="el-dropdown-link">
                    是否同步<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="-1">全部</el-dropdown-item>
                    <el-dropdown-item command="1">是</el-dropdown-item>
                    <el-dropdown-item command="0">否</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <template slot-scope="scope">
                <div
                  style="width: 60px; margin: 0 auto;"
                  :class="{ red: scope.row.isSync == '否' }"
                >
                  {{ scope.row.isSync }}
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="isNotic" align="center" width="120">
              <template slot="header">
                <el-dropdown
                  class="dropdown"
                  @command="($evens) => command('isNotic', $evens)"
                >
                  <span class="el-dropdown-link">
                    是否通知<i class="el-icon-arrow-down el-icon--right"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="-1">全部</el-dropdown-item>
                    <el-dropdown-item command="1">是</el-dropdown-item>
                    <el-dropdown-item command="0">否</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </template>
              <template slot-scope="scope">
                <div
                  style="width: 60px; margin: 0 auto;"
                  :class="{ red: scope.row.isNotic == '否' }"
                >
                  {{ scope.row.isNotic }}
                </div>
              </template>
            </el-table-column>
          </template>

          <el-table-column
            prop="carBrand"
            label="品牌"
            width="100"
            align="center"
          >
            <div slot-scope="scope">
              {{ scope.row.carBrand ? scope.row.carBrand : "-" }}
            </div>
          </el-table-column>
          <el-table-column
            prop="carSeries"
            label="车系"
            width="100"
            align="center"
          >
            <div slot-scope="scope">
              {{ scope.row.carSeries ? scope.row.carSeries : "-" }}
            </div>
          </el-table-column>
          <el-table-column label="负责人" width="100" align="center">
            <div slot-scope="scope">
              {{ scope.row.contacts ? scope.row.contacts : "-" }}
            </div>
          </el-table-column>
          <el-table-column label="联系方式" min-width="120" align="center">
            <div slot-scope="scope">
              {{ scope.row.contactsPhone ? scope.row.contactsPhone : "-" }}
            </div>
          </el-table-column>
          <el-table-column
            v-if="!isGcAdmin"
            label="操作"
            width="200"
            align="center"
            fixed="right"
          >
            <template slot-scope="scope">
              <div
                style="height: 30px; display: flex; align-items: center; justify-content: center;"
              >
                <el-tooltip
                  :content="
                    scope.row.strictStatus === 1
                      ? '打开单北斗信号'
                      : '关闭单北斗信号'
                  "
                  placement="top"
                >
                  <el-button
                    type="text"
                    size="mini"
                    @click="handleStrictStatus(scope.row)"
                    icon="el-icon-lock"
                    :disabled="handleStrictStatusDisabled(scope.row)"
                  >
                  </el-button>
                </el-tooltip>
                <template v-if="!isHideAcc">
                  <el-tooltip
                    content="修改单北斗设备号"
                    placement="top"
                    v-if="scope.row.bdNum"
                  >
                    <el-button
                      type="text"
                      size="mini"
                      @click="handleEditOnly(scope.row)"
                      icon="el-icon-edit-outline"
                    >
                    </el-button>
                  </el-tooltip>
                  <el-tooltip content="添加单北斗设备号" placement="top" v-else>
                    <el-button
                      type="text"
                      size="mini"
                      @click="handleAddOnly(scope.row)"
                      icon="el-icon-circle-plus-outline"
                    >
                    </el-button>
                  </el-tooltip>
                </template>
                <el-tooltip content="修改" placement="top">
                  <el-button
                    type="text"
                    size="mini"
                    @click="edit(scope.row)"
                    icon="el-icon-edit"
                  >
                  </el-button>
                </el-tooltip>
                <el-tooltip content="删除" placement="top">
                  <el-button
                    type="text"
                    style="color: #F56C6C"
                    size="mini"
                    @click="delHandle(scope.row)"
                    icon="el-icon-delete"
                  ></el-button>
                </el-tooltip>
                <!-- 隐藏 同步 按钮 改为批量 2023.10.18 产品：赵嘉宾 -->
                <el-button
                  v-if="false"
                  type="primary"
                  size="mini"
                  @click="updateData(scope.row)"
                  >同步</el-button
                >
                <!-- <el-button type="danger" size="mini" @click="handleIsStop('', scope.row)"
                >启停</el-button
              > -->
                <!-- 隐藏 刷新上报时间 按钮 改为批量 2023.10.18 产品：赵嘉宾 -->
                <el-button
                  v-if="false"
                  type="primary"
                  size="mini"
                  @click="resetTime(scope.row)"
                  >刷新上报时间</el-button
                >

                <el-tooltip
                  content="清除缓存"
                  placement="top"
                  v-if="!isHideAcc"
                >
                  <el-button
                    type="text"
                    style="color: #F56C6C"
                    size="mini"
                    @click="clearCache(scope.row)"
                    :disabled="scope.row.type == 2"
                  >
                    <div style="height: 100%; padding-top: 3px;">
                      <svg
                        t="1703669109943"
                        class="icon"
                        viewBox="0 0 1024 1024"
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        p-id="1914"
                        width="12"
                        height="12"
                      >
                        <path
                          d="M574.464 998.4c-22.528 0-44.032-8.704-59.904-25.088L44.032 502.784c-33.28-33.28-33.28-87.04 0-119.808l302.08-302.08c15.872-15.872 37.376-25.088 59.904-25.088s44.032 8.704 59.904 25.088l141.824 141.824 175.104-175.104c15.872-15.872 37.376-25.088 59.904-25.088s44.032 8.704 59.904 25.088l67.072 67.072c33.28 33.28 33.28 87.04 0 119.808L794.624 409.6l141.824 141.824c15.872 15.872 25.088 37.376 25.088 59.904 0 22.528-8.704 44.032-25.088 59.904l-302.08 302.08c-15.872 16.384-37.376 25.088-59.904 25.088z m-82.432-151.552l40.96 40.96c10.752 10.752 25.6 16.896 40.96 16.896s29.696-6.144 40.96-16.896l108.032-108.032-485.376-485.376-107.52 108.032c-10.752 10.752-16.896 25.6-16.896 40.96s6.144 29.696 16.896 40.96l40.96 40.96 67.072-67.072 52.736 52.736-67.072 67.072 81.92 81.92 100.864-100.864 52.736 52.736L358.4 712.704l81.92 81.92 66.56-68.096 52.736 52.736-67.584 67.584z m-86.016-696.32c-15.36 0-29.696 6.144-40.96 16.896L290.816 241.664l484.864 484.864 74.24-74.24c22.528-22.528 22.528-59.392 0-81.92L689.152 409.6l211.456-211.456c13.312-13.312 13.312-34.816 0-48.128l-33.792-33.792c-6.656-6.656-14.848-9.728-24.064-9.728-9.216 0-17.408 3.584-24.064 9.728L607.232 327.68 446.976 167.424c-11.264-11.264-25.6-16.896-40.96-16.896z"
                          fill="#333333"
                          p-id="1915"
                        ></path>
                      </svg>
                    </div>
                  </el-button>
                </el-tooltip>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="totalCount"
          layout="total, sizes, prev, pager, next, jumper"
        >
        </el-pagination>
      </div>
    </div>
    <add-dev
      ref="add-dev"
      :orgData="orgData"
      @refreshDataList="getDevList"
      :isHideAcc="isHideAcc"
    ></add-dev>

    <el-dialog
      title="请选择导出数据类型"
      :visible.sync="showExportPanel"
      width="540px"
    >
      <el-radio v-model="state" :label="0">导出全部</el-radio>
      <el-radio v-model="state" :label="1">导出离线</el-radio>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showExportPanel = false">取消</el-button>
        <el-button type="primary" @click="exportData">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="showIsShop" width="600px">
      <h3>{{ msg }}</h3>
      <div style="height: 40px"></div>
      <el-checkbox v-model="isHide">一小时内不再提示</el-checkbox>
      <span slot="footer" class="dialog-footer">
        <el-button @click="cancel">取 消</el-button>
        <el-button type="primary" @click="handleHide">确 定</el-button>
      </span>
    </el-dialog>
    <map-container ref="mapContainer" />
  </div>
</template>

<script>
import { treeDataTranslate, exportTable, setCookie, getCookie } from "@/utils";
import addDev from "@/views/modules/device-add-or-update";
import mapContainer from "@/components/amap.vue";

export default {
  name: "device-manage",
  components: { addDev, mapContainer },
  data() {
    const loginData = JSON.parse(localStorage.getItem("login-data"));
    const typeMap = new Map([
      [0, "麦卡途"],
      [1, "亚美"],
      [2, "有线设备"],
      [3, "BD8826LHA700"],
      [4, "交投设备"],
      [5, "54所设备"],
    ]);
    return {
      typeMap,
      typeList: [...typeMap],
      $user: loginData,
      loginData,
      isGcAdmin: loginData.username === "gcadmin",
      orgTree: [],
      defaultProps: {
        children: "children",
        label: "nodeName",
      },
      curNode: {},
      tableData: [],
      pageNo: 1,
      pageSize: 10,
      totalCount: 0,
      id: "", //查询条件  设备号
      carPlateNum: "",
      carPlateColor: "",
      time: ["", ""],
      type: "-1",
      startTime: "",
      endTime: "",
      isStop: "-1",
      isSync: "-1",
      isNotic: "-1",
      ids: "",
      filterText: "",
      showExportPanel: false, // 导出控制面板
      state: 0, // 导出数据类型
      loading: false,
      showIsShop: false,
      isHide: false,
      isStopChangeData: {},
      msg: "",
      clientHeight: 0,
    };
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
  computed: {
    defaultExpanded() {
      let _B = Boolean(this.orgTree && this.orgTree.length);
      let arr = _B ? [this.orgTree[0].id] : [];
      return arr;
    },
    orgData() {
      return this.curNode;
    },
    // 是否隐藏功能账号（交投石家庄帐号）
    isHideAcc() {
      return [
        "交投石家庄帐号",
        "交投张家口帐号",
        "交投承德帐号",
        "交投沧州帐号",
      ].includes(this.loginData.username);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.clientHeight =
        this.$refs.container.clientHeight -
        this.$refs.buttonOut.clientHeight -
        // 分页高度32px + margin-top 15px
        (32 + 15);
    });
    this.getOrgList();
  },
  methods: {
    filterNode(value, data) {
      if (!value) return true;
      return data.nodeName.indexOf(value) !== -1;
    },
    getOrgList() {
      this.$http({
        url: this.$http.adornUrl("/sys/orgArch/list"),
        methods: "get",
      })
        .then((res) => {
          this.orgTree = treeDataTranslate(res.data.data, "id", "parendId");
          this.curNode = JSON.parse(JSON.stringify(this.orgTree[0]));
          this.getDevList();
        })
        .catch((err) => {
          this.$message.error(err.msg);
        });
    },
    treeNodeClick(node) {
      this.curNode = JSON.parse(JSON.stringify(node));
      this.currentChangeHandle(1);
    },
    getDevList() {
      if (this.curNode.id == "") return this.$message.warning("请选择单位!");
      if (isNaN(this.id)) {
        return this.$message.warning("设备号格式不正确!");
      }
      const Loading = this.$mask();
      this.$http({
        url: this.$http.adornUrl("/obd/list"),
        method: "get",
        params: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          orgId: this.curNode.id,
          carPlateNum: this.carPlateNum,
          carPlateColor: this.carPlateColor,
          id: this.id,
          type: this.type == "-1" ? "" : this.type,
          startTime: this.time?.[0] ?? "", // 开始时间
          endTime: this.time?.[1] ?? "", // 结束时间
          isStop: this.isStop == "-1" ? "" : this.isStop, // 是否停用 0正常 1停用
          isSync: this.isSync == "-1" ? "" : this.isSync, // 是否同步 0未同步 1已同步
          isNotic: this.isNotic == "-1" ? "" : this.isNotic, // 是否通知 0未通知 1已通知
        },
      })
        .then((res) => {
          Loading.close();
          res.data.page.list = res.data.page.list || [];
          res.data.page.list.forEach((element) => {
            element.isNotic = element.isNotic === 0 ? "否" : "是";
            element.isSync = element.isSync === 0 ? "否" : "是";
          });
          this.tableData = res.data.page.list;
          this.totalCount = res.data.page.totalCount || 0;
        })
        .catch((err) => {
          Loading.close();
          this.$message.error(err.msg);
        });
    },
    sizeChangeHandle(val) {
      this.pageSize = val;
      this.pageNo = 1;
      this.getDevList();
    },
    currentChangeHandle(val) {
      this.pageNo = val;
      this.getDevList();
    },
    reset() {
      this.carPlateNum = "";
      this.carPlateColor = "";
      this.id = "";
      this.type = "-1";
      this.time = ["", ""];
      this.isStop = "-1";
      this.isSync = "-1";
      this.isNotic = "-1";
      this.currentChangeHandle(1);
    },
    addHandle() {
      if (!this.curNode || !this.curNode.id)
        return this.$message.warning("未选择节点!");
      // if (this.curNode.nodeType != 3)
      //   return this.$message.warning("请选择单位!");
      this.$confirm(
        `确定将设备创建在 【${this.curNode.nodeName}】 节点吗?`,
        "友情提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.$refs["add-dev"].init();
      });
    },
    cancelNoticeHandle() {
      if (0 == this.ids.length) {
        return this.$message.error("请选择车辆");
      }
      this.$confirm(
        `正在【取消通知】【${this.ids.split(",").length}】个设备，是否继续?`,
        "取消通知",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        const Loading = this.$mask();
        this.$http({
          url: this.$http.adornUrl("/obd/cancelnotice"),
          method: "put",
          params: {
            ids: this.ids,
          },
        })
          .then((res) => {
            Loading.close();
            this.$message.success(res.data.msg);
            this.getDevList();
          })
          .catch((err) => {
            Loading.close();
            this.$message.error(err.msg);
          });
      });
    },
    noticeHandle() {
      if (0 == this.ids.length) {
        return this.$message.error("请选择车辆");
      }
      this.$confirm(
        `正在【通知】【${this.ids.split(",").length}】个设备，是否继续?`,
        "通知",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        const Loading = this.$mask();
        this.$http({
          url: this.$http.adornUrl("/obd/notice"),
          method: "put",
          params: {
            ids: this.ids,
          },
        })
          .then((res) => {
            Loading.close();
            this.$message.success(res.data.msg);
            this.getDevList();
          })
          .catch((err) => {
            Loading.close();
            this.$message.error(err.msg);
          });
      });
    },
    handleSelectionChange(val) {
      var ids = val.map((el) => el.id).join();
      this.ids = ids;
    },
    handleIsStop(row) {
      const Loading = this.$mask();
      var num = 0;
      if (row.isStop == 1) {
        num = 1;
      }
      // this.$confirm(
      //   `确定对[设备编号:${row.id}][车牌号:${row.carPlateNum}]进行[${msg}]操作?`,
      //   "提示",
      //   {
      //     confirmButtonText: "确定",
      //     cancelButtonText: "取消",
      //     type: "warning",
      //   }
      // )
      //   .then(() => {
      //     return this.$http({
      //       url: this.$http.adornUrl("/obd/isstopequipment"),
      //       method: "get",
      //       params: {
      //         id: row.id,
      //         isStop: num,
      //       },
      //     });
      //   })
      this.$http({
        url: this.$http.adornUrl("/obd/isstopequipment"),
        method: "get",
        params: {
          id: row.id,
          isStop: num,
        },
      })
        .then((res) => {
          Loading.close();
          this.showIsShop = false;
          this.$message.success(res.data.msg);
          this.getDevList();
        })
        .catch((err) => {
          Loading.close();
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
    delHandle(row) {
      const Loading = this.$mask();
      this.$confirm(`确定对[设备编号:${row.id}]进行[删除]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return this.$http({
            url: this.$http.adornUrl("/obd/del"),
            method: "delete",
            params: {
              id: row.id,
            },
          });
        })
        .then((res) => {
          Loading.close();
          this.$message.success(res.data.msg);
          this.getDevList();
        })
        .catch((err) => {
          Loading.close();
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
    clearCache(row) {
      const Loading = this.$mask();
      this.$confirm(`确定对[设备编号:${row.id}]进行[清除缓存]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return this.$http({
            url: this.$http.adornUrl("/sys/equipment/clearinfo"),
            method: "get",
            params: {
              equipmentId: row.id,
            },
          });
        })
        .then((res) => {
          Loading.close();
          this.$message.success(res.data.msg);
          this.getDevList();
        })
        .catch((err) => {
          Loading.close();
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
    updateData(row) {
      const Loading = this.$mask();
      this.$confirm(`确定对[设备编号:${row.id}]进行[同步]操作?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          return this.$http({
            url: this.$http.adornUrl("/obd/synchronize"),
            method: "put",
            params: {
              id: row.id,
            },
          });
        })
        .then((res) => {
          Loading.close();
          this.$message.success(res.data.msg);
          this.getDevList();
        })
        .catch((err) => {
          Loading.close();
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
    batchUpdate() {
      if (this.ids == "") return this.$message.warning("请选择设备");
      const Loading = this.$mask();
      this.$confirm(
        `正在【批量同步】【${this.ids.split(",").length}】个设备，是否继续?`,
        "批量同步",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          return this.$http({
            url: this.$http.adornUrl("/obd/synchronize"),
            method: "put",
            params: {
              id: this.ids,
            },
          });
        })
        .then((res) => {
          Loading.close();
          this.$message.success(res.data.msg);
          this.getDevList();
        })
        .catch((err) => {
          Loading.close();
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
    edit(row) {
      this.$refs["add-dev"].init(row);
    },
    // 点击导出按钮
    handleExport() {
      this.state = 0;
      this.showExportPanel = true;
    },
    //导出
    exportData() {
      const Loading = this.$mask();
      if (!this.isAuth("obd:exportEquipmentInfo"))
        return this.$message.warning("没有权限!obd:exportEquipmentInfo");
      this.$http({
        url: this.$http.adornUrl("obd/exportEquipmentInfo"),
        method: "get",
        responseType: "arraybuffer",
        params: {
          pageNo: this.pageNo,
          pageSize: this.pageSize,
          orgId: this.curNode.id,
          carPlateNum: this.carPlateNum,
          carPlateColor: this.carPlateColor,
          id: this.id,
          state: this.state,
          type: this.type == "-1" ? "" : this.type,
        },
      })
        .then((res) => {
          this.showExportPanel = false;
          Loading.close();
          exportTable(res, "设备车辆信息表");
        })
        .catch((err) => {
          Loading.close();
        });
    },
    isStopChange(val, row) {
      // row["isStopLoading"] = true;
      this.isStopChangeData = row;
      let date = Number(getCookie("hide_stop_equipment_warn"));
      if (date && Date.now() - 1000 * 60 * 60 < date) {
        return this.handleIsStop(this.isStopChangeData);
      }
      this.msg = `确定对[设备编号:${row.id}][车牌号:${row.carPlateNum}]进行[${
        row.isStop == 1 ? "停用" : "启用"
      }]操作?`;
      this.showIsShop = true;
    },
    handleHide() {
      if (this.isHide) {
        setCookie("hide_stop_equipment_warn", Date.now().toString());
      }
      this.handleIsStop(this.isStopChangeData);
    },
    cancel() {
      this.showIsShop = false;
      this.isStopChangeData["isStop"] =
        this.isStopChangeData["isStop"] == 1 ? 0 : 1;
    },
    // 刷新最后定位上报时间
    resetTime(row) {
      if (!row && this.ids == "") {
        return this.$message.warning("请选择设备");
      }
      this.$refs.mapContainer.show(row?.id ?? this.ids);
    },
    // 点击批量关闭GPS按钮
    handleCloseGPS() {
      if (!this.ids) return this.$message.warning("请勾选需要关闭的设备");
      const Loading = this.$mask();
      this.$confirm(
        `正在【批量关闭GPS】【${this.ids.split(",").length}】个设备，是否继续?`,
        "批量关闭GPS",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          return this.$http({
            url: this.$http.adornUrl("sys/close/gps"),
            method: "put",
            params: {
              ids: this.ids,
            },
          });
        })
        .then((res) => {
          Loading.close();
          this.$message.success(res.data.msg);
          this.getDevList();
        })
        .catch((err) => {
          Loading.close();
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
    command(key, val) {
      this[key] = val;
      this.currentChangeHandle(1);
    },
    // 添加单北斗设备号
    handleAddOnly(data) {
      let _loading = null;
      this.$prompt(
        "请输入单北斗设备号",
        `添加单北斗设备号 - ${data.carPlateNum}`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /^\d{11}$/,
          inputErrorMessage: "请输入11位设备号",
        }
      )
        .then(({ value }) => {
          _loading = this.$mask();
          return this.$http({
            url: this.$http.adornUrl("/obd/add/bdnum"),
            method: "get",
            params: {
              id: data.id,
              bdNum: value,
            },
          });
        })
        .then((res) => {
          _loading.close();
          this.$message.success(res.data.msg);
          this.getDevList();
        })
        .catch((err) => {
          _loading.close();
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
    // 更新单北斗设备号
    handleEditOnly(data) {
      let _loading = null;
      this.$prompt(
        "请输入单北斗设备号",
        `修改单北斗设备号 - ${data.carPlateNum}`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputPattern: /^\d{11}$/,
          inputErrorMessage: "请输入11位设备号",
          inputValue: data.bdNum,
        }
      )
        .then(({ value }) => {
          _loading = this.$mask();
          return this.$http({
            url: this.$http.adornUrl("/obd/update/bdnum"),
            method: "get",
            params: {
              id: data.id,
              bdNum: value,
            },
          });
        })
        .then((res) => {
          _loading.close();
          this.$message.success(res.data.msg);
          this.getDevList();
        })
        .catch((err) => {
          _loading.close();
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
    // 清空单北斗设备号
    handleRemoveOnly({ carPlateNum, id }) {
      const Loading = this.$mask();
      this.$confirm(`是否清空车牌号【${carPlateNum}】的单北斗设备号？`, "提示")
        .then(() => {
          return this.$http({
            url: this.$http.adornUrl("/obd/remove/bdnum"),
            method: "delete",
            params: { id },
          });
        })
        .then((res) => {
          Loading.close();
          this.$message.success(res.data.msg);
          this.getDevList();
        })
        .catch((err) => {
          Loading.close();
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
    // 添加/取消涉密
    handleStrictStatus(row) {
      // strictStatus 1是关闭单北斗信号 0打开单北斗信号
      const Loading = this.$mask();
      this.$confirm(
        `是否${row.strictStatus === 1 ? "打开单北斗信号" : "关闭单北斗信号"}`,
        "提示"
      )
        .then(() => {
          return this.$http({
            url: this.$http.adornUrl("/obd/strict/set"),
            method: "post",
            data: { id: row.id, strictStatus: row.strictStatus === 1 ? 0 : 1 },
          });
        })
        .then((res) => {
          Loading.close();
          this.$message.success(res.data.msg);
          this.getDevList();
        })
        .catch((err) => {
          Loading.close();
          if (err && err.msg) {
            this.$message.error(err.msg);
          }
        });
    },
    handleStrictStatusDisabled(row) {
      if (["3754", "3755", "1487", "1001"].includes(row.id.substring(0, 4))) {
        return false;
      }
      return true;
    },
  },
};
</script>
<style scoped>
.red {
  background-color: #f78989;
  color: #fff;
  display: block;
  width: 100%;
  height: 100%;
}
.dropdown {
  /* height: 23px; */
  line-height: normal;
  color: #909399;
}
::v-deep .el-table th > .cell {
  display: flex;
  align-items: center;
  justify-content: center;
}
.container-left {
  width: 255px;
}
.container-left-tree {
  width: 100%;
  overflow: auto;
}
::v-deep .el-tree-node > .el-tree-node__children {
  overflow: visible;
}
.container-right {
  width: calc(100% - 265px);
  height: 100%;
  float: right;
}
::v-deep .el-range-editor.el-input__inner {
  justify-content: center;
  padding-left: 0px;
  padding-right: 0px;
  width: 360px;
}
::v-deep .el-table-column--selection .cell {
  padding-right: 10px;
}
.device-manage {
  height: 100%;
}
.mr1 {
  margin-right: 5px;
}
</style>
