<template>
  <el-dialog :title="dialogTitle" :visible.sync="visible" width="580px">
    <el-form
      :model="formData"
      label-width="100px"
      label-position="left"
      :rules="rules"
      ref="formRef"
    >
      <el-form-item label="所属客户" prop="customer">
        <el-input
          v-model="formData.customer"
          class="fixedWidth400"
          size="small"
          placeholder="所属客户"
        ></el-input>
      </el-form-item>
      <el-form-item label="网站名称" prop="siteName">
        <el-input
          v-model="formData.siteName"
          class="fixedWidth400"
          size="small"
          placeholder="网站名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="网站域名" prop="domainName">
        <el-input
          v-model="formData.domainName"
          class="fixedWidth400"
          size="small"
          placeholder="网站域名"
        ></el-input>
      </el-form-item>
      <el-form-item label="上传Logo">
        <PhotoSingle
          ref="logoRef"
          :value="imageValue.logo"
          @on-change="(file) => handleChange('logo', file)"
        />
      </el-form-item>
      <el-form-item label="背景图">
        <PhotoSingle
          ref="backImageRef"
          :value="imageValue.backImage"
          @on-change="(file) => handleChange('backImage', file)"
        />
      </el-form-item>
      <el-form-item label="网站名称图片">
        <PhotoSingle
          ref="siteNameImageRef"
          :value="imageValue.siteNameImage"
          @on-change="(file) => handleChange('siteNameImage', file)"
        />
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="cancel()">取 消</el-button>
      <el-button type="primary" @click="submit()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import PhotoSingle from "@/components/photoSingle";
export default {
  name: "domainAddEdit",
  components: { PhotoSingle },
  data() {
    return {
      formData: initFormData(),
      // 图片回显
      imageValue: {
        backImage: "",
        logo: "",
        siteNameImage: ""
      },
      rules: {
        customer: { required: true, message: "请输入", trigger: "blur" },
        domainName: { required: true, message: "请输入", trigger: "blur" },
        siteName: { required: true, message: "请输入", trigger: "blur" },
      },
      visible: false,
      dialogTitle: "",
    };
  },
  methods: {
    init(data = {}) {
      this.formData = initFormData(data);
      this.imageValue.backImage = data.backImage || "";
      this.imageValue.logo = data.logo || "";
      this.imageValue.siteNameImage = data.siteNameImage || "";
      if (data.id) {
        this.dialogTitle = "修改域名";
      } else {
        this.dialogTitle = "新增域名";
      }
      this.visible = true;
    },
    // 点击取消按钮
    cancel() {
      this.formData = initFormData();
      this.visible = false;
    },
    submit() {
      this.$refs.formRef.validate((valid) => {
        if (!valid) return;
        const Loading = this.$mask();
        this.$http({
          url: this.$http.adornUrl("/domain/name/manage/addorupdate"),
          method: "post",
          data: this.$http.FormData({
            id: this.formData.id,
            backImage: this.formData.backImage,
            siteNameImage: this.formData.siteNameImage,
            domainName: this.formData.domainName,
            logo: this.formData.logo,
            siteName: this.formData.siteName,
            customer: this.formData.customer,
          }),
        })
          .then((res) => {
            Loading.close();
            this.$message.success(res.data.msg);
            this.cancel();
            this.$emit("success");
          })
          .catch((err) => {
            Loading.close();
            this.$message.error(err.msg);
          });
      });
    },
    // 上传文件
    handleChange(key, file) {
      this.formData[key] = file;
    },
  },
};
function initFormData(data = {}) {
  return {
    id: data?.id ?? "",
    backImage: data?.backImage ?? "",
    siteNameImage: data?.siteNameImage ?? "",
    domainName: data?.domainName ?? "",
    logo: data?.logo ?? "",
    siteName: data?.siteName ?? "",
    customer: data?.customer ?? "",
  };
}
</script>
