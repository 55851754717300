<template>
  <el-upload
    action
    :multiple="false"
    :auto-upload="false"
    :accept="originAccept"
    :show-file-list="false"
    :on-change="handleChange"
  >
    <div class="photoload-div">
      <div class="photoload-close" @click.stop="clear" v-if="oneFile">
        <Icon type="md-close" />
      </div>
      <template v-if="oneFile || value">
        <div v-if="isImg" class="photoload-img" :style="previewImg"></div>
        <template v-else>
          <i class="el-icon-document" style="font-size: 60px"></i>
          <span class="photoload-type">{{ fileType }}文件</span>
        </template>
      </template>
      <el-popover v-else width="300" placement="top" trigger="hover">
        <template #reference>
          <Icon type="md-camera" size="60" />
        </template>
        <div class="photoload-rule">
          <div v-if="accepts">支持{{ accepts }}格式的文件</div>
          <div>文件不能超过{{ maxSize }}MB</div>
        </div>
      </el-popover>
    </div>
  </el-upload>
</template>
<script>
const defineImg = ["jpg", "jpeg", "png", "bmp"];
export default {
  props: {
    value: String,
    maxSize: {
      type: Number,
      default: 300
    },
    accept: {
      type: String,
      default: defineImg.join(".")
    }
  },
  watch: {
    value() {
      this.oneFile = null;
    }
  },
  computed: {
    accepts() {
      return this.accept?.split(".").join("，");
    },
    originAccept() {
      return (
        this.accept
          ?.split(".")
          .map((el) => "." + el)
          .join(",") || null
      );
    },
    fileType() {
      return (this.oneFile?.name || this.value).split(".").pop().toLowerCase();
    },
    isImg() {
      return defineImg.indexOf(this.fileType) !== -1;
    },
    preview() {
      return this.oneFile ? URL.createObjectURL(this.oneFile) : this.value;
    },
    previewImg() {
      return { backgroundImage: `url("${this.preview}")` };
    }
  },
  methods: {
    clear() {
      this.$emit("on-change", null);
      this.oneFile = null;
    },
    getFile() {
      return this.oneFile;
    },
    handleChange(file) {
      if (file.status != "ready") return;
      if (this.accept.indexOf(file.name.split(".").pop().toLowerCase()) === -1) {
        this.formatError();
        return;
      }
      if (file.size > this.maxSize * 1024 * 1024) {
        this.sizeError();
        return;
      }
      this.oneFile = file.raw;
      this.$emit("on-change", file.raw);
    },
    sizeError() {
      this.$Message.warning({
        duration: 3,
        content: "文件大小已超过限制"
      });
    },
    formatError() {
      this.$Message.warning({
        duration: 3,
        content: "文件格式与规定不符"
      });
    }
  },
  data() {
    return {
      oneFile: null
    };
  }
};
</script>

<style scoped lang="less">
.photoload {
  &-div {
    width: 228px;
    height: 155px;
    cursor: pointer;
    border: dashed 1px #e1e1e1;
    border-radius: 6px;
    transition:
      border 0.2s,
      color 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4px;
    position: relative;
    &:hover {
      border-color: #17B3A3;
      color: #17B3A3;
    }
  }
  &-img {
    width: 100%;
    height: 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &-close {
    position: absolute;
    right: 2px;
    top: 2px;
    color: #ed4014;
    font-size: 18px;
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    &:hover {
      color: #fff;
      background: #8c939d;
    }
  }
  &-rule > div {
    padding: 4px 0;
  }
}
</style>